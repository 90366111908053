import { PartnersUserDoc, PartnersVehicleDoc } from '@gooduncles/gu-app-schema';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { Button, ColorPicker, notification } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { map } from 'rxjs';
import { updatePartnerColor } from 'src/utils/partners-util';

import { permissionStateBall } from 'src/lib/1/constant';
import { formatDate } from 'src/lib/1/date-util';
import { errorObjectToString } from 'src/lib/1/util';
import { createPartnersVehicle, observePartnersUser, observePartnersVehicle } from 'src/lib/4/firebase-short-cut';
import { createOnRowDragEndHandler, onCellValueChangedWithUpdate } from 'src/lib/6/ag-grid-util';

import classes from './PartnersUserTable.module.scss';

import DeleteVehicleButton from './DeleteVehicleButton/DeleteVehicleButton';

const partnersUserPath = 'partnersUser';
const partnersVehiclePath = 'partnersVehicle';
const onCellValueChanged = onCellValueChangedWithUpdate<PartnersUserDoc>('파트너 관리');
const onRowDragEnd = createOnRowDragEndHandler(partnersUserPath, 'deliveryDriverStatus.sortKey');

const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  filter: true,
};

const PartnersUserGridTable: FC<{ rowData: PartnersUserDoc[]; partnersVehicles: PartnersVehicleDoc[] }> = ({
  rowData,
  partnersVehicles,
}) => {
  const columnDefs: ColDef<PartnersUserDoc>[] = useMemo(
    () => [
      {
        field: '_id',
        hide: true,
      },
      {
        width: 100,
        field: 'deliveryDriverStatus.sortKey',
        headerName: '순서',
        rowDrag: true,
      },
      {
        width: 120,
        cellRenderer: 'agGroupCellRenderer',
        field: 'nickname',
        headerName: '담당자',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, partnersUserPath, params.data._id),
      },
      {
        hide: true,
        field: 'name',
        headerName: '담당자(실명)',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, partnersUserPath, params.data._id),
      },
      {
        width: 170,
        field: 'userTel',
        headerName: '전화번호',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, partnersUserPath, params.data._id),
      },
      {
        width: 100,
        field: 'deliveryDriverStatus.color',
        headerName: '색상',
        cellRenderer: (params: any) => {
          const { deliveryDriverStatus, _id } = params.data as PartnersUserDoc;
          return (
            <ColorPicker
              value={deliveryDriverStatus.color}
              onChangeComplete={(color) => updatePartnerColor(_id, color.toHexString())}
              defaultValue='#ffffff'
            />
          );
        },
      },
      {
        field: 'deliveryDriverStatus.isActive',
        headerName: '상태',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [true, false],
          cellRenderer: ({ value }: any) => <span>{value ? '🟢 활성' : '⚪️ 비활성'}</span>,
        },
        cellRenderer: ({ value }: any) => (
          <span>{value !== undefined ? (value ? '🟢 활성' : '⚪️ 비활성') : 'unknown'}</span>
        ),
        onCellValueChanged: (params) => onCellValueChanged(params, partnersUserPath, params.data._id),
      },
      {
        width: 140,
        field: 'deliveryDriverStatus.vehicleId',
        headerName: '챠랑번호',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [...partnersVehicles.map((vehicle) => vehicle._id), null],
          cellRenderer: ({ value }: any) => {
            if (value === null) return <span>-</span>;
            const vehicle = partnersVehicles.find((vehicle) => vehicle._id === value);
            return <span>{vehicle?.vehicleNumber ?? '?'}</span>;
          },
        },
        cellRenderer: ({ value }: any) => {
          if (value === null) return <span>-</span>;
          const vehicle = partnersVehicles.find((vehicle) => vehicle._id === value);
          return <span>{vehicle?.vehicleNumber ?? '?'}</span>;
        },
        onCellValueChanged: (params) =>
          onCellValueChanged(params, partnersUserPath, params.data._id, undefined, undefined, true),
      },
      {
        width: 240,
        field: 'email',
        headerName: '이메일',
      },
    ],
    [partnersVehicles]
  );

  /**
   * 파트너의 기기 정보를 보여주기 위한 렌더러 설정
   */
  const detailCellRendererParams = useMemo(() => {
    // TODO: 구조 변경에 따른 수정 필요
    const detailColumnDefs: ColDef<any>[] = [
      {
        headerName: '업데이트 시각',
        field: 'updatedAt',
        valueFormatter: (params) => formatDate(new Date(params.value), 'yyyy-MM-dd HH:mm:ss'),
      },
      { headerName: '앱 버전', field: 'readableVersion', width: 120 },
      { headerName: '기기명', field: 'deviceName', width: 140 },
      { headerName: '제조사', field: 'manufacturer', width: 100 },
      { headerName: 'OS', field: 'systemName', width: 120 },
      { headerName: 'OS version', field: 'systemVersion', width: 130 },
      { headerName: 'deviceUniqueId', field: 'deviceUniqueId' },
      { headerName: 'token', field: 'token' },
      {
        headerName: '알림',
        width: 88,
        field: 'permissions.notification',
        valueGetter: (params) => permissionStateBall[params.data?.permissions.notification ?? ''] ?? '⚪️',
      },
      {
        headerName: '위치',
        width: 88,
        field: 'permissions.location',
        valueGetter: (params) => permissionStateBall[params.data?.permissions.location ?? ''] ?? '⚪️',
      },
      {
        headerName: '카메라',
        width: 96,
        field: 'permissions.camera',
        valueGetter: (params) => permissionStateBall[params.data?.permissions.camera ?? ''] ?? '⚪️',
      },
      {
        headerName: '사진첩',
        width: 96,
        field: 'permissions.location',
        valueGetter: (params) => permissionStateBall[params.data?.permissions.photo ?? ''] ?? '⚪️',
      },
      {
        headerName: '최초 설치 일자',
        field: 'firstInstallTime',
        valueFormatter: (params) => formatDate(new Date(params.value), 'yyyy-MM-dd HH:mm:ss'),
      },
    ];

    return {
      detailGridOptions: {
        columnDefs: detailColumnDefs,
        defaultColDef,
      },
      getDetailRowData: (params: any) => {
        const partnersUserDoc = params.data as PartnersUserDoc;
        const devices = (partnersUserDoc as any).devices;
        params.successCallback(devices);
      },
    };
  }, []);

  return (
    <div className='ag-theme-alpine height100 flex2'>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        context={{ partnersVehicles }}
        rowData={rowData}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        // 순서 드래그
        rowDragManaged={true}
        rowDragMultiRow={true}
        rowSelection='multiple'
        animateRows={true}
        onRowDragEnd={onRowDragEnd}
        suppressMoveWhenRowDragging={true}
        enableCellTextSelection={true}
        // DetailRow
        keepDetailRows={true}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRendererParams={detailCellRendererParams}
      />
    </div>
  );
};

const PartnersVehicleGridTable: FC<{ rowData: PartnersVehicleDoc[] }> = ({ rowData }) => {
  const columnDefs: ColDef<PartnersUserDoc>[] = useMemo(
    () => [
      {
        field: '_id',
        hide: true,
      },
      {
        width: 160,
        field: 'vehicleNumber',
        headerName: '차량번호',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, partnersVehiclePath, params.data._id),
      },
      {
        width: 180,
        field: 'vehicleModel',
        headerName: '차량 모델',
        editable: true,
        onCellValueChanged: (params) => onCellValueChanged(params, partnersVehiclePath, params.data._id),
      },
      {
        width: 120,
        headerName: '액션',
        cellRenderer: DeleteVehicleButton,
      },
    ],
    []
  );

  return (
    <div className='ag-theme-alpine height100 flex1'>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        // rowId를 지정해주지 않으면 데이터 변경시 refresh가 발생한다.
        getRowId={(params) => params.data._id}
        // 편집 완료후 스크롤 이동을 막는다.
        suppressScrollOnNewData={true}
        animateRows={true}
      />
    </div>
  );
};

const PartnersUserTable: FC = () => {
  const [partnersUsers, setPartnersUsers] = useState<PartnersUserDoc[]>([]);
  const [partnersVehicles, setPartnersVehicles] = useState<PartnersVehicleDoc[]>([]);
  const [loading, setLoading] = useState(false);

  const addNewVehicle = useCallback(async () => {
    setLoading(true);
    try {
      await createPartnersVehicle({
        vehicleNumber: '신규 차량',
        vehicleModel: '신규 차량',
        vehicleImageUrl: null,
      });
    } catch (error) {
      console.error(error);
      const description = errorObjectToString(error);
      notification.error({
        message: '차량 추가 실패',
        description,
      });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    const partnersUserSubscription = observePartnersUser().subscribe((docs) =>
      setPartnersUsers(docs.sort((a, b) => a.deliveryDriverStatus.sortKey - b.deliveryDriverStatus.sortKey))
    );
    const partnersVehicleSubscription = observePartnersVehicle()
      .pipe(map((docs) => docs.sort((a, b) => a.vehicleModel.localeCompare(b.vehicleModel))))
      .subscribe(setPartnersVehicles);
    return () => {
      partnersUserSubscription.unsubscribe();
      partnersVehicleSubscription.unsubscribe();
    };
  }, []);

  return (
    <div className={classes.partnersTableContainer}>
      <div className={classes.buttons}>
        <Button loading={loading} onClick={addNewVehicle}>
          차량 추가
        </Button>
      </div>
      <div className={classes.tables}>
        <PartnersUserGridTable rowData={partnersUsers} partnersVehicles={partnersVehicles} />
        <PartnersVehicleGridTable rowData={partnersVehicles} />
      </div>
    </div>
  );
};

export default PartnersUserTable;
